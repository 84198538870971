@use "../components/_mixins";
@use "../components/_extends";
@use "../components/_colors";
@use "../components/_main";
@use "../components/_cards";
@use "../components/_filterMenu";
@use "../components/_titles";
@use "../components/_collectionSummary";
@use "../components/_fourCardsCarousel";
@use "../components/_whiteHeader";
@use "../components/_stickyFooterSearch";
@use "../components/_modal";

main{overflow: visible;}
.title{padding: 126px 0 99px;
    @include mixins.mq(860){padding: 80px 0 50px;}
    @include mixins.mq(500){padding: 60px 0 30px;}
}
.filter-menu{margin-bottom: 31px;}
.collection-summary{margin-bottom: 60px;
    @include mixins.mq(500){margin-bottom: 50px;}
}
.cards{padding-bottom: 80px;}
.dark-bg{padding-bottom:120px; margin-bottom: 120px;
    @include mixins.mq(860){padding-bottom:80px; }
    @include mixins.mq(500){padding-bottom:60px; }
}

button{
    &.square{margin: 0 auto 110px; display:block; font-size:1.8rem; padding: 0 25px;}
}

main .container {
    overflow: unset; //filter menus
}

.hide {
    display:none;
}

// main{  
//     &>div:nth-child(7){margin-top: 80px;
//         @media(max-width: 930px){margin-top:67px;}
//         @media(max-width: 760px){margin-top: 60px;}
//     }
// }