@use "_mixins";
@use "_extends";
@use "_colors";
.collection-summary{display:flex;
    &__left{flex:1; padding: 53px 38px 54px 63px; display: flex; flex-direction: column; justify-content: space-between; position:relative;
        label{@include mixins.font(1.2, 1.2, 1.63rem, 400, 0.2rem, white); display:flex; align-items:center; text-transform:uppercase; gap:10px; z-index:3;}
        &:after{content:""; @extend %pin; background-color: rgba(black, 0.25); z-index:1;}
        @include mixins.mq(1100){padding: 53px 38px 50px 40px;}
        @include mixins.mq(720){padding: 50px 30px 50px 30px; @include mixins.bgRatio(380, 530, 40);}
    }

    .collection-summary__titles{display:flex; justify-content:space-between; align-items:center; gap:30px; z-index:3;
        h2{@include mixins.font(3.8, 4.4, 110%, 800, 0, white); flex: 1.35;
            i{font-family: "Magazine Grotesque", serif; font-weight:400;}
        }
        p{@include mixins.font(1.4, 1.6, 140%, 400, 0, white); padding-left:20px; border-left:1px solid white; flex:1;}
        @include mixins.mq(1100){flex-direction: column; align-items: flex-start; flex:1; padding: 40px 0;
            h2, p{flex:none;}
            p{padding-left: 14px;}
        }
    }

    .collection-summary__tags{display:flex; align-items:center; gap:5px; z-index:3; flex-wrap: wrap;
        li{@include mixins.font(1.2, 1.2, 1.2rem, 400, 0, white); border: 1px solid white; padding: 10px 10px; border-radius:2px;}
    }

    &__right{background-color:#44562B; flex:0 0 404px; padding: 47px 43px 63px;
        i{@include mixins.font(1.6, 1.6, 1.63rem, 400, 0, white, "Magazine Grotesque");
              span{font-family: "Archivo", sans-serif; font-weight:700; font-style:normal; text-transform:uppercase; letter-spacing:0.05rem;}
        }

        ul{display: flex; flex-direction: column; gap: 26px; margin-top: 37px; flex-wrap:wrap;
            li{display:flex; align-items:flex-start; gap: 11px;
                label{@include mixins.font(1.2, 1.2, 1.2rem, 700, 0.04rem, white); text-transform:uppercase;}
                p{@include mixins.font(1.2, 1.4, 1.96rem, 400, 0, white); margin-top: 6px;}
            }
        }
        @include mixins.mq(1100){flex:0 0 334px; padding: 40px 50px 40px;}
        @include mixins.mq(720){padding: 40px 30px 40px;}
    }
    @include mixins.mq(720){flex-direction: column;}
}