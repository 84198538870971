@use "_mixins";
@use "_extends";
@use "_colors";
.four-cards-carousel{position:relative;
    &__header{padding: 80px 0 80px;
        h2{display:flex; flex-wrap: wrap; align-items:center; justify-content:center; padding: 0 20px; @include mixins.font(1.8, 2.4, 120%, 400, 0.42rem, var(--color-off-black),  "Magazine Grotesque"); text-transform:uppercase; gap: 9px;
            i{font-style:italic; text-transform:none; font-family: "Magazine Grotesque", serif; letter-spacing:0;}
        }
        p{@include mixins.font(1.4, 1.6, 1.5, 400, 0, var(--color-off-black)); text-align:center; margin: 21px 0 0;}
        @include mixins.mq(860){padding: 80px 0 40px;
        h2{
            img{width: 100px;}
        }
        }
        @include mixins.mq(500){padding: 60px 0 40px;}
    }
    .swiper{//height: 600px;
        .swiper-wrapper{
            .swiper-slide{width:294px; border-radius: 4px; overflow:hidden; display:flex; flex-direction:column;
                .four-cards-carousel__image{height:294px; flex: none;}
                &>div{padding: 20px 21px; min-height:246px; position:relative; flex:1;
                    label{@include mixins.font(1.1, 1.1, 1.48rem, 600, 0.17rem, var(--color-off-black)); display:flex; align-items:center; gap:7px; text-transform:uppercase;}
                    h3{@include mixins.font(2.4, 2.8, 130%, 500, -0.02rem, var(--color-off-black), "Magazine Grotesque"); margin: 17px 0 11px;}
                    p{@include mixins.font(1.2, 1.4, 1.96rem, 400, 0, var(--color-off-black)); overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 4;}       
                    small{@include mixins.font(1.2, 1.2, 140%, 400, 0, var(--color-off-black)); text-transform:uppercase; position:absolute; bottom: 19px; left: 22px;}
                    .curated{margin-top: 36px;
                        label{display:block; @include mixins.font(1, 1, 1.36rem, 600, 0.07rem, var(--color-off-black)); text-transform:uppercase; margin-bottom:4px;}
                    }
                }
                @include mixins.mq(600){width: 220px;
                    .four-cards-carousel__image{height:220px; min-height: 220px;}
                }
            }
        }        
    }
    .swiper-button-prev, .swiper-button-next{margin-top:0; top: 68%;}
    .swiper-button-next{right:-20px;}
    .swiper-button-prev{left:-20px;}
}